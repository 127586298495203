import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { APICall } from "api/client";
import IWInput from "components/input/Input";
import { MINIMUM_LAUNCHPAD_PURCHASE } from "constants";
import { useAppContext } from "contexts/AppContext";
import { parseUnits } from "ethers";
import { multiplePrice } from "pages/launchpad/create/utils";
import { parsePrice } from "pages/launchpad/create/utils";
import { isValidAddress } from "pages/launchpad/create/utils";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { AiFillExclamationCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchLaunchpads } from "redux/slices/launchpadSlice";
import { formatDecimalNumberToString } from "utils";
import { roundUp } from "utils";
import { resolveAZDomainToAddress } from "utils";
import { roundDown } from "utils";
import { delay } from "utils";
import { execContractTx } from "utils/contracts";
import launchpad from "utils/contracts/launchpad";

const AddSingleWL = ({
  launchpadData,
  selectedPhase,
  selectedWL,
  setSelectedWL,
  availableTokenAmount,
  phaseCapAmount,
  whitelist,
  fetchPhaseData,
  availableWLAmount
}) => {
  const { currentAccount } = useSelector((state) => state.wallet);
  const { api } = useAppContext();
  const [wlData, setWLData] = useState({
    address: "",
    amount: "",
    price: "",
  });
  useEffect(() => {
    setWLData(
      selectedWL
        ? {
            address: selectedWL?.account,
            amount: (+selectedWL?.amount).toString(),
            price: (+selectedWL?.price).toString(),
          }
        : {
            address: "",
            amount: "",
            price: "",
          }
    );
  }, [selectedWL]);
  const dispatch = useDispatch();

  const addSingleWLHandler = async () => {
    try {
      const wladdress = (await resolveAZDomainToAddress(wlData?.address)) || (isValidAddress(wlData?.address) && wlData?.address)
      if(!wladdress) {
        toast.error(`Address or ID not valid`);
        return;
      }
      // if (availableTokenAmount * 1 <= 0) {
      //   toast.error(`No available token amount!`);
      //   return;
      // }
      const WLAmount = +wlData?.amount || 0
      const WLPrice = +wlData?.price || 0
      const a0price = multiplePrice(WLAmount, WLPrice);
      if(a0price < +MINIMUM_LAUNCHPAD_PURCHASE && WLPrice > 0) {
        toast.error(
          `Minimum total A0 sell is 0.1, minimum token amount with price ${formatDecimalNumberToString(WLPrice)} is ${roundUp(0.1 / WLPrice)}`
        );
        return;
      }
      if(WLAmount > availableWLAmount) {
        toast.error(
          `Max whitelist amount is ${availableWLAmount}`
        );
        return;
      }
      if (phaseCapAmount < WLAmount) {
        toast.error(
          `Whitelist amount can not be greater than phase cap amount!`
        );
        return;
      }

      const currentWl = launchpadData?.phaseList[selectedPhase]?.whitelist;
      if (currentWl.some((obj) => obj.address === wladdress)) {
        toast.error("Whitelist address existed");
        return;
      }
      // if (WLAmount > +availableTokenAmount) {
      //   toast.error(`Maximum amount is ${availableTokenAmount}`);
      //   return;
      // }
      const result = await execContractTx(
        currentAccount,
        api,
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0, //-> value
        "launchpadContractTrait::addMultiWhitelists",
        selectedPhase,
        [wladdress],
        [
          parseUnits(
            WLAmount.toString(),
            parseInt(launchpadData?.projectInfo?.token.decimals)
          ),
        ],
        [parsePrice(WLPrice, 12)]
      );
      await APICall.askBEupdate({
        type: "launchpad",
        poolContract: launchpadData?.launchpadContract,
      });
      fetchPhaseData()
      if (result) {
        setWLData({
          address: "",
          amount: "",
          price: "",
        });
        toast.promise(
          delay(4000).then(() => {
            dispatch(fetchLaunchpads({}));
          }),
          {
            loading: "Please wait up to 4s for the data to be updated! ",
            success: "Whitelist updated",
            error: "Could not fetch data!!!",
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSingleWLHandler = async () => {
    try {
      // if (+availableTokenAmount <= 0) {
      //   toast.error(`No available token amount!`);
      //   return;
      // }
      const WLAmount = +wlData?.amount || 0
      const oldWLAmount = +selectedWL?.amount
      const WLPrice = +wlData?.price || 0

      if (WLAmount - oldWLAmount > availableWLAmount) {
        toast.error(
          `Maximum new amount is ${availableWLAmount + oldWLAmount}`
        );
        return;
      }
      if (WLAmount < 0) {
        toast.error("Invalid token amount");
        return;
      }
      const result = await execContractTx(
        currentAccount,
        api,
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0, //-> value
        "launchpadContractTrait::updateMultiWhitelists",
        selectedPhase,
        [wlData?.address],
        [
          parseUnits(
            WLAmount.toString(),
            parseInt(launchpadData?.projectInfo?.token.decimals)
          ),
        ],
        [parsePrice(WLPrice, 12)]
      );
      await APICall.askBEupdate({
        type: "launchpad",
        poolContract: launchpadData?.launchpadContract,
      });
      if (result) {
        setWLData({
          address: "",
          amount: "",
          price: "",
        });
        toast.promise(
          delay(4000).then(() => {
            dispatch(fetchLaunchpads({}));
          }),
          {
            loading: "Please wait up to 4s for the data to be updated! ",
            success: "Whitelist updated",
            error: "Could not fetch data!!!",
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isWhitelistEditable = useMemo(() => {
    return +selectedWL?.claimedAmount > 0;
  }, [selectedWL]);

  // ++++++++++++++++++++++++++
  const [inWLList, setINWLList] = useState(false)
  useEffect(()=> {
    (async () => {
      const wladdress = (await resolveAZDomainToAddress(wlData?.address)) || (isValidAddress(wlData?.address) && wlData?.address)
      setINWLList(whitelist?.map((i) => i.account).includes(wladdress))
    })()
  }, [whitelist, wlData?.address])
  useEffect(() => {
    (async () => {
      if (inWLList) {
        const wladdress = (await resolveAZDomainToAddress(wlData?.address)) || (isValidAddress(wlData?.address) && wlData?.address)
        const found = whitelist?.find((i) => i.account === wladdress);
        console.log(selectedWL)
        setSelectedWL(found)
        setWLData((prev) => ({ ...prev, ...found }));
      } else {
        setWLData((prev) => ({
          ...prev,
          amount: "",
          price: "",
        }));
      }
    })()
  }, [inWLList, whitelist, wlData?.address]);

  return (
    <Box sx={{ pt: "0px" }}>
      {isWhitelistEditable ? (
        <>
          <Box
            sx={{
              bg: "#FED1CA",
              display: "flex",
              alignItems: "center",
              px: "10px",
              py: "8px",
              mt: "10px",
              borderRadius: "4px",
            }}
          >
            <AiFillExclamationCircle />
            <Text sx={{ ml: "8px" }}>
              You can not edit this whitelist account
            </Text>
          </Box>
          <Button
            w="full"
            disabled={!selectedWL}
            m="16px 2px"
            size="md"
            sx={{ bg: "#F6F6FC" }}
            _hover={{ bg: "#E3E1EC" }}
            onClick={() => setSelectedWL(null)}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <>
            <Text sx={{ fontWeight: "700" }}>Whitelist Address</Text>
            <IWInput
              disabled={launchpadData?.requireKyc && !selectedWL}
              size="md"
              value={wlData?.address}
              width={{ base: "full" }}
              onChange={({ target }) =>
                setWLData({ ...wlData, address: target.value })
              }
              placeholder="Address"
            />

            <Text sx={{ fontWeight: "700" }}>Amount</Text>
            <IWInput
              disabled={launchpadData?.requireKyc && !selectedWL}
              type="number"
              size="md"
              value={wlData?.amount}
              width={{ base: "full" }}
              onChange={({ target }) =>
                setWLData({ ...wlData, amount: target.value })
              }
              placeholder="0"
            />

            <Text sx={{ fontWeight: "700" }}>Price</Text>
            <IWInput
              disabled={launchpadData?.requireKyc && !selectedWL}
              type="number"
              size="md"
              value={wlData?.price}
              width={{ base: "full" }}
              onChange={({ target }) =>
                setWLData({ ...wlData, price: target.value })
              }
              placeholder="0"
            />
          </>

          {inWLList ? (
            <Box
              alignItems="center"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                w="full"
                disabled={!selectedWL}
                m="16px 2px"
                size="md"
                sx={{ bg: "#F6F6FC" }}
                _hover={{ bg: "#E3E1EC" }}
                onClick={() => setSelectedWL(null)}
              >
                Cancel
              </Button>

              <Button
                w="full"
                isDisabled={
                  !selectedWL ||
                  !(
                    wlData?.address?.length > 0 &&
                    wlData?.amount?.length > 0 &&
                    (wlData?.address !== selectedWL?.account ||
                      wlData?.amount !== (+selectedWL?.amount).toString() ||
                      wlData?.price !== (+selectedWL?.price).toString())
                  )
                }
                m="16px 2px"
                size="md"
                onClick={() => updateSingleWLHandler()}
              >
                Update
              </Button>
            </Box>
          ) : (
            <Flex>
              {!launchpadData?.requireKyc ? (
                <>
                  <Button
                    w="full"
                    // disabled={!selectedWL}
                    m="16px 2px"
                    size="md"
                    sx={{ bg: "#F6F6FC" }}
                    _hover={{ bg: "#E3E1EC" }}
                    onClick={() => {
                      setWLData({
                        address: "",
                        amount: "",
                        price: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    isDisabled={
                      !(
                        wlData?.address?.length > 0 &&
                        wlData?.amount?.length > 0
                      )
                    }
                    m="16px 2px"
                    w="full"
                    size="md"
                    onClick={() => addSingleWLHandler()}
                  >
                    Add New
                  </Button>
                </>
              ) : null}
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};
export default AddSingleWL;
