import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { APICall } from "api/client";
import NumberInputWrapper from "components/input/NumberInput";
import { useAppContext } from "contexts/AppContext";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchLaunchpads } from "redux/slices/launchpadSlice";
import { formatTokenAmountNumber } from "utils";
import { formatNumToBN } from "utils";
import { delay } from "utils";
import { formatChainStringToNumber } from "utils";
import { formatQueryResultToNumber } from "utils";
import { formatNumDynDecimal, formatTokenAmount } from "utils";
import { execContractTx } from "utils/contracts";
import { execContractQuery } from "utils/contracts";
import launchpad from "utils/contracts/launchpad";
import psp22_contract from "utils/contracts/psp22_contract";
import psp22_contract_v2 from "utils/contracts/psp22_contract_V2";
import * as Yup from "yup";

const EditTotalSupply = ({ visible, setVisible, launchpadData }) => {
  const currentAccount = useSelector((s) => s.wallet.currentAccount);
  const { api } = useAppContext();
  const dispatch = useDispatch();

  const [availableTokenAmount, setAvailableTokenAmount] = useState(0);

  const tokenDecimal = parseInt(launchpadData?.projectInfo?.token?.decimals);
  const isPhaseStart =
    Date.now() > formatChainStringToNumber(launchpadData?.startTime);
  const isOwner = launchpadData?.owner === currentAccount?.address;
  const tokenSymbol = launchpadData?.projectInfo?.token?.symbol;
  const tokenAddress = launchpadData?.projectInfo?.token?.tokenAddress;
  const totalSupply =
    formatChainStringToNumber(launchpadData?.totalSupply) /
    Math.pow(10, tokenDecimal);

  const fetchPhaseData = async () => {
    const result = await execContractQuery(
      currentAccount?.address,
      api,
      launchpad.CONTRACT_ABI,
      launchpadData?.launchpadContract,
      0,
      "launchpadContractTrait::getAvailableTokenAmount"
    );
    const availableAmount = result.toHuman().Ok;
    setAvailableTokenAmount(
      +formatTokenAmountNumber(availableAmount, tokenDecimal)
    );
  };

  useEffect(() => {
    if (!visible) {
      // setOnCreateNew(false);
      // setNewData(null);
      // setSelectedPhaseIndex(-1);
    } else {
      if (launchpadData) fetchPhaseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, launchpadData]);

  const minAllowed = totalSupply - availableTokenAmount;

  const [tokenBalance, setTokenBalance] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const queryResult = await execContractQuery(
        currentAccount?.address,
        api,
        psp22_contract.CONTRACT_ABI,
        tokenAddress,
        0,
        "psp22::balanceOf",
        currentAccount?.address
      );
      const tokenBalanceQR = queryResult?.toHuman()?.Ok;
      const tokenBal = +formatTokenAmountNumber(tokenBalanceQR, tokenDecimal);
      setTokenBalance(tokenBal);
    };
    api && fetchData();
  }, [api, currentAccount?.address, tokenAddress]);

  return (
    <>
      <Modal
        isOpen={visible}
        isCentered
        size="xl"
        onClose={() => setVisible(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{ totalSupply }}
            validationSchema={() =>
              Yup.lazy((values) => {
                if (values?.totalSupply > totalSupply) {
                  return Yup.object().shape({
                    totalSupply: Yup.number()
                      .required(`This field is required`)
                      .min(
                        minAllowed,
                        `Total Supply must be greater than or equal to ${minAllowed} ${tokenSymbol}`
                      )
                      .max(
                        totalSupply + tokenBalance,
                        tokenBalance === 0
                          ? `Can not topup because your balance has zero ${tokenSymbol}`
                          : `Total Supply must be less than or equal to ${
                              totalSupply + tokenBalance
                            } ${tokenSymbol}`
                      ),
                  });
                } else {
                  return Yup.object().shape({
                    totalSupply: Yup.number()
                      .required(`This field is required`)
                      .min(
                        minAllowed,
                        `Total Supply must be greater than or equal to ${minAllowed} ${tokenSymbol}`
                      ),
                  });
                }
              })
            }
            onSubmit={async (values) => {
              console.log("isPhaseStart", isPhaseStart);
              if (isPhaseStart) {
                return toast.error("You can not edit when project started!");
              }

              if (!isOwner) {
                return toast.error("Only owner can edit!");
              }

              try {
                // check approve additional portion
                const additionalPortion = values?.totalSupply - totalSupply;

                if (additionalPortion > 0) {
                  toast("Approving approve additional token...");

                  const allowanceTokenQr = await execContractQuery(
                    currentAccount?.address,
                    "api",
                    psp22_contract_v2.CONTRACT_ABI,
                    launchpadData?.projectInfo?.token?.tokenAddress,
                    0, //-> value
                    "psp22::allowance",
                    currentAccount?.address,
                    launchpadData?.launchpadContract
                  );

                  const allowanceToken =
                    allowanceTokenQr?.toHuman().Ok?.replaceAll(",", "") /
                    10 ** launchpadData?.projectInfo?.token?.decimals;

                  if (additionalPortion > allowanceToken) {
                    await execContractTx(
                      currentAccount,
                      "api",
                      psp22_contract_v2.CONTRACT_ABI,
                      launchpadData?.projectInfo?.token?.tokenAddress,
                      0, //-> value
                      "psp22::approve",
                      launchpadData?.launchpadContract,
                      formatNumToBN(
                        additionalPortion,
                        launchpadData?.token?.decimals
                      )
                    );
                  }
                }
                // End check approve additional portion

                const result = await execContractTx(
                  currentAccount,
                  api,
                  launchpad.CONTRACT_ABI,
                  launchpadData?.launchpadContract,
                  0, //-> value
                  "launchpadContractTrait::setTotalSupply",
                  formatNumToBN(values?.totalSupply)
                );

                if (result) {
                  await delay(200);

                  await APICall.askBEupdate({
                    type: "launchpad",
                    poolContract: launchpadData?.launchpadContract,
                  });

                  toast.promise(
                    delay(5000).then(() => {
                      setVisible(false);
                      dispatch(fetchLaunchpads({}));
                    }),
                    {
                      loading:
                        "Please wait up to 5s for the data to be updated! ",
                      success: "Success",
                      error: "Could not fetch data!!!",
                    }
                  );
                }
              } catch (error) {
                console.log("error", error);
              }
            }}
          >
            {({ dirty, isValid, isSubmitting, values }) => (
              <Form>
                <ModalHeader>Edit total token for sale</ModalHeader>

                <ModalCloseButton onClick={() => setVisible(false)} />
                <ModalBody
                  sx={{ pb: "28px", maxHeight: "80vh", overflow: "auto" }}
                >
                  <SimpleGrid
                    w="full"
                    columns={{ base: 1, lg: 2 }}
                    spacingX={{ lg: "20px" }}
                    spacingY={{ base: "0px", lg: "20px" }}
                    mb={{ base: "30px" }}
                  >
                    <Text>Current total supply:</Text>
                    <Box
                      sx={{ display: "flex" }}
                      justifyContent={["flex-start", "flex-start", "flex-end"]}
                      mb={["12px", "12px", "0px"]}
                    >
                      <Text>{`${formatNumDynDecimal(
                        parseInt(totalSupply)
                      )} ${tokenSymbol}`}</Text>
                    </Box>

                    <Text>Distributed token to all phases: </Text>
                    <Box
                      sx={{ display: "flex" }}
                      justifyContent={["flex-start", "flex-start", "flex-end"]}
                      mb={["12px", "12px", "0px"]}
                    >
                      <Text>{`${formatNumDynDecimal(
                        parseInt(totalSupply) - availableTokenAmount
                      )} ${tokenSymbol}`}</Text>
                    </Box>

                    <Text>Undistributed token: </Text>
                    <Box
                      sx={{ display: "flex" }}
                      justifyContent={["flex-start", "flex-start", "flex-end"]}
                      mb={["12px", "12px", "0px"]}
                    >
                      <Text>{`${formatNumDynDecimal(
                        availableTokenAmount
                      )} ${tokenSymbol}`}</Text>
                    </Box>

                    <Text>Your balance:</Text>
                    <Box
                      sx={{ display: "flex" }}
                      justifyContent={["flex-start", "flex-start", "flex-end"]}
                      mb={["12px", "12px", "0px"]}
                    >
                      <Text>{`${formatNumDynDecimal(tokenBalance)} ${tokenSymbol}`}</Text>
                    </Box>
                  </SimpleGrid>

                  <Stack>
                    <Flex
                      flexDirection={["column", "column", "row"]}
                      justifyContent="space-between"
                    >
                      <Text textAlign="left" color="brand.grayLight">
                        {`New total token for sale `}
                      </Text>

                      {values?.totalSupply > totalSupply ? (
                        <Text textAlign="left" color="brand.grayLight">
                          {` (max ${formatNumDynDecimal(
                            totalSupply + tokenBalance
                          )} ${tokenSymbol})`}
                        </Text>
                      ) : null}
                    </Flex>
                    <NumberInputWrapper
                      type="number"
                      name="totalSupply"
                      hasStepper={false}
                      step={1}
                      precision={0}
                      placeholder="Royalty Fee"
                      isDisabled={isSubmitting}
                      min={minAllowed}
                      max={totalSupply + tokenBalance}
                    />
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    w="full"
                    type="submit"
                    isDisabled={!(dirty && isValid) || isSubmitting}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditTotalSupply;
