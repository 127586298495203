import { Box, Button, Heading, Progress, Text } from "@chakra-ui/react";
import { APICall } from "api/client";
import { AzeroLogo } from "components/icons/Icons";
import IWInput from "components/input/Input";
import { appChain } from "constants";
import { toastMessages } from "constants";
import { useAppContext } from "contexts/AppContext";
import { parseUnits } from "ethers";
import { useMemo, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { fetchLaunchpads } from "redux/slices/launchpadSlice";
import { fetchUserBalance } from "redux/slices/walletSlice";
import { multipleFloat } from "utils";
import { formatDecimalNumberToString } from "utils";
import { formatNumDynDecimal } from "utils";
import { formatChainStringToNumber } from "utils";
import { formatTokenAmountNumber } from "utils";
import {
  delay,
  formatNumToBN,
  formatTokenAmount,
  roundDown,
  roundUp,
} from "utils";
import { execContractQuery, execContractTx } from "utils/contracts";
import launchpad from "utils/contracts/launchpad";
import { multiplePrice } from "../create/utils";
import { MINIMUM_LAUNCHPAD_PURCHASE } from "constants";

const headerSX = {
  fontWeight: "700",
  color: "#57527E",
};

const TimeBox = ({ value, isLast = false }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          background: "#FEEEBD",
          width: "40px",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontWeight: "bold",
          borderRadius: "4px",
        }}
      >
        {value}
      </Box>
      {isLast || (
        <Box
          sx={{
            fontWeight: "bold",
            marginLeft: "4px",
            marginRight: "4px",
          }}
        >
          :
        </Box>
      )}
    </Box>
  );
};

const SaleCount = ({ label, time, direction }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <TimeBox value={zeroPad(days)} />
          <TimeBox value={zeroPad(hours)} />
          <TimeBox value={zeroPad(minutes)} />
          <TimeBox value={zeroPad(seconds)} isLast />
        </Box>
      );
    }
  };
  return (
    <Box>
      <Heading sx={{ textAlign: "center" }} size="md">
        {label}
      </Heading>
      {time ? (
        <Countdown date={time} renderer={renderer} />
      ) : (
        <Text sx={{ fontWeight: "bold", color: "#57527E" }}>
          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <TimeBox value={"00"} />
            <TimeBox value={"00"} />
            <TimeBox value={"00"} />
            <TimeBox value={"00"} isLast />
          </Box>
        </Text>
      )}
    </Box>
  );
};

const IWCountDown = ({ saleTime, launchpadData }) => {
  const renderer = ({ completed }) => {
    const now = Date.now();
    const livePhase = saleTime?.find((e) => {
      return now > e.startTime && now < e.endTime;
    });
    const nearestPhase = saleTime?.reduce((acc, object) => {
      if (!acc && object?.startTime > now) return object;
      else {
        if (acc?.startTime > object?.startTime && object?.startTime > now)
          return object;
        else return acc;
      }
    }, null);
    if (completed) {
      return (
        <Box sx={{ paddingBottom: "10px" }}>
          <SaleCount label="Ended" time={null} />
          {nearestPhase?.name && (
            <>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <Text>Upcoming phase: </Text>
                <Text sx={{ fontWeight: "700", color: "#57527E" }}>
                  {" "}
                  {nearestPhase?.name}
                </Text>
              </Box>
              <SaleLayout launchpadData={launchpadData} livePhase={null} />
            </>
          )}
        </Box>
      );
    } else if (livePhase) {
      return (
        <Box>
          <SaleCount label="Sale end in" time={livePhase?.endTime} />
          <Box sx={{ display: "flex", marginTop: "20px" }}>
            <Text mr="4px">Active phase:</Text>
            <Text sx={{ fontWeight: "600", color: "#57527E" }}>
              {livePhase?.name}
            </Text>
          </Box>
          {livePhase?.publicSaleInfor?.isPublic &&
          livePhase?.whitelist?.length > 0
            ? `(Public sale/Whitelist sale)`
            : livePhase?.publicSaleInfor?.isPublic
            ? `(Public sale)`
            : livePhase?.whitelist?.length > 0
            ? `(Whitelist Only)`
            : null}
          {livePhase?.publicSaleInfor?.isPublic && (
            <SaleLayout
              launchpadData={launchpadData}
              livePhase={livePhase}
              allowBuy
            />
          )}
        </Box>
      );
    } else if (nearestPhase) {
      return (
        <Box>
          <SaleCount label="Sale starts in" time={nearestPhase?.startTime} />
          <Box sx={{ display: "flex", marginTop: "20px" }}>
            <Text>Upcoming phase: </Text>
            <Text sx={{ fontWeight: "bold", color: "#57527E" }}>
              {" "}
              {nearestPhase?.name}
            </Text>
          </Box>
          <SaleLayout launchpadData={launchpadData} livePhase={nearestPhase} />
        </Box>
      );
    } else return null;
  };
  const endTime = saleTime[saleTime?.length - 1]?.endTime;
  if (saleTime?.length > 0)
    return <Countdown date={endTime} renderer={renderer} />;
  else return null;
};
const SaleLayout = ({ launchpadData, livePhase, allowBuy }) => {
  const { currentAccount } = useSelector((s) => s.wallet);
  const { api } = useAppContext();
  const [amount, setAmount] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [azeroBuyAmount, setAzeroBuyAmount] = useState(0);
  const [isBuyWithA0, setIsBuyWithA0] = useState(false);
  const [publicSaleAmount, setPublicSale] = useState({
    purchased: 0,
    total: 0,
  });
  const [amountError, setAmountError] = useState(false);

  const dispatch = useDispatch();

  const saleQuery = useQuery(
    ["query-public-sale", currentAccount, launchpadData],
    async () => {
      if (currentAccount)
        await new Promise(async (resolve) => {
          await getPublicSaleInfo();
          resolve();
        });
    }
  );
  const getWLInfo = async (phaseID) => {
    try {
      const txRateQuery = await execContractQuery(
        currentAccount?.address,
        api,
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0,
        "launchpadContractTrait::getTxRate"
      );
      const txRate = +txRateQuery?.toHuman()?.Ok / 10000;
      const queryResult = await execContractQuery(
        currentAccount?.address,
        api,
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0,
        "launchpadContractTrait::getWhitelistSaleInfo",
        phaseID
      );
      const WLInfo = queryResult?.toHuman()?.Ok;
      const totalPurchasedAmountPhase =
        WLInfo?.totalPurchasedAmount &&
        +formatTokenAmountNumber(
          WLInfo?.totalPurchasedAmount,
          launchpadData?.projectInfo?.token?.decimals
        );
      const queryCountWL = await execContractQuery(
        currentAccount?.address,
        api,
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0,
        "launchpadContractTrait::getWhitelistAccountCount",
        phaseID
      );
      const countWL = queryCountWL?.toHuman()?.Ok;
      const WLList = await Promise.all(
        new Array(+countWL).fill(0).map(async (e, index) => {
          const queryWLAccount = await execContractQuery(
            currentAccount?.address,
            api,
            launchpad.CONTRACT_ABI,
            launchpadData?.launchpadContract,
            0,
            "launchpadContractTrait::getWhitelistAccount",
            phaseID,
            index
          );
          const WLAccount = queryWLAccount?.toHuman()?.Ok;
          const queryWLAccountDetail = await execContractQuery(
            currentAccount?.address,
            api,
            launchpad.CONTRACT_ABI,
            launchpadData?.launchpadContract,
            0,
            "launchpadContractTrait::getWhitelistBuyer",
            phaseID,
            WLAccount
          );
          const WLAccountDetail = queryWLAccountDetail?.toHuman()?.Ok;
          const formatedAccountBuyer = {
            price: +formatTokenAmountNumber(
              WLAccountDetail?.price,
              appChain?.decimals
            ),
            purchasedAmount: +formatTokenAmountNumber(
              WLAccountDetail?.purchasedAmount,
              tokenDecimal
            ),
            amount: +formatTokenAmountNumber(
              WLAccountDetail?.amount,
              tokenDecimal
            ),
          };
          return formatedAccountBuyer;
        })
      );
      const totalSaledAzero = WLList.reduce((acc, current) => {
        return acc + multipleFloat(current?.price, current?.purchasedAmount);
      }, 0);
      return {
        WLList,
        totalWL: WLList.reduce((acc, current) => {
          return acc + (current?.amount || 0);
        }, 0),
        totalAmount:
          WLInfo?.totalAmount &&
          +formatTokenAmountNumber(
            WLInfo?.totalAmount,
            launchpadData?.projectInfo?.token?.decimals
          ),
        totalPurchasedAmount: totalPurchasedAmountPhase,
        azeroAmount: multipleFloat(totalSaledAzero, 1 - txRate),
      };
    } catch (error) {
      console.log(error);
    }
  };
  const purchasePublicHandler = async () => {
    try {
      if (!api) {
        toast.error(toastMessages.ERR_API_CONN);
        return;
      }

      if (!currentAccount) {
        toast.error(toastMessages.NO_WALLET);
        return;
      }
      if (+amount + +publicSaleAmount?.purchased > +publicSaleAmount?.total) {
        toast.error(
          `Current max public sale available is ${formatNumDynDecimal(
            +publicSaleAmount?.total - +publicSaleAmount?.purchased
          )}`
        );
        return;
      }
      const a0BuyAmount = isBuyWithA0
        ? +azeroBuyAmount
        : multiplePrice(amount, tokenPrice);
      const buyResult = await execContractTx(
        currentAccount,
        api,
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        parseUnits(a0BuyAmount.toString(), 12), //-> value
        "launchpadContractTrait::publicPurchase",
        livePhase?.id,
        formatNumToBN(+amount, +launchpadData.projectInfo.token.decimals)
      );
      if (!buyResult) return;
      await delay(400);
      await APICall.askBEupdate({
        type: "launchpad",
        poolContract: launchpadData?.launchpadContract,
      });
      setAmount(0);
      setAzeroBuyAmount(0);
      saleQuery.refetch();
      await delay(4000);
      if (currentAccount) {
        dispatch(fetchUserBalance({ currentAccount, api }));
        dispatch(fetchLaunchpads({}));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const publicBuyMutation = useMutation(async () => {
    await new Promise(async (resolve) => {
      await purchasePublicHandler();
      resolve();
    });
  }, "public_purchase");
  const getPublicSaleInfo = async () => {
    try {
      const result0 = await execContractQuery(
        currentAccount?.address,
        "api",
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0,
        "launchpadContractTrait::getPublicSaleTotalAmount",
        livePhase?.id
      );
      const publicSaleTotalAmount = result0.toHuman()?.Ok;
      const result = await execContractQuery(
        currentAccount?.address,
        "api",
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0,
        "launchpadContractTrait::getPublicSaleTotalPurchasedAmount",
        livePhase?.id
      );
      const publicSaleTotalBuyedAmount = result.toHuman()?.Ok;
      setPublicSale({
        total: +formatTokenAmountNumber(
          publicSaleTotalAmount,
          launchpadData.projectInfo.token.decimals
        ),
        purchased: +formatTokenAmountNumber(
          publicSaleTotalBuyedAmount,
          launchpadData.projectInfo.token.decimals
        ),
      });
      const result1 = await execContractQuery(
        currentAccount?.address,
        "api",
        launchpad.CONTRACT_ABI,
        launchpadData?.launchpadContract,
        0,
        "launchpadContractTrait::getPublicSalePrice",
        livePhase?.id
      );
      const publicSalePrice = result1.toHuman()?.Ok;
      setTokenPrice(+formatTokenAmountNumber(publicSalePrice, 12));
    } catch (error) {
      console.log(error);
    }
  };

  const whitelistData = launchpadData?.phaseList[livePhase?.id]?.whitelist;
  const tokenDecimal = launchpadData?.projectInfo?.token?.decimals;
  const totalWL = whitelistData?.reduce((acc, cur) => {
    return (acc += +formatTokenAmountNumber(cur?.amount, tokenDecimal));
  }, 0);
  const totalWLPurchased = whitelistData?.reduce((acc, cur) => {
    return (acc += +formatTokenAmountNumber(
      cur?.purchasedAmount,
      tokenDecimal
    ));
  }, 0);
  const phaseCap = +formatTokenAmountNumber(
    launchpadData?.phaseList[livePhase?.id]?.capAmount,
    tokenDecimal
  );
  const totalPurchase = publicSaleAmount?.total + totalWL;
  const maxPurchaseAmount = totalPurchase > phaseCap ? phaseCap : totalPurchase;
  const totalPurchasedPubnWL = publicSaleAmount?.purchased + totalWLPurchased;
  const progressPublicSaleRatio = useMemo(
    () =>
      publicSaleAmount?.total != 0
        ? roundUp(
            ((publicSaleAmount?.purchased || 0) /
              (publicSaleAmount?.total || 0)) *
              100
          )
        : 0,
    [publicSaleAmount]
  );
  const isBuyDisabled = useMemo(() => {
    return (
      !launchpadData?.isActive ||
      !allowBuy ||
      !(parseFloat(amount) > 0) ||
      !(
        formatChainStringToNumber(publicSaleAmount?.total) -
          formatChainStringToNumber(publicSaleAmount?.purchased) >
        0
      ) || amountError
    );
  }, [allowBuy, amount, publicSaleAmount]);

  const maxAmount = useMemo(
    () =>
      formatChainStringToNumber(publicSaleAmount?.total) -
      formatChainStringToNumber(publicSaleAmount?.purchased),
    [publicSaleAmount]
  );

  return (
    <>
      <Box sx={{ marginTop: "12px" }}>
        <Text>Progress {`(${progressPublicSaleRatio}%)`}</Text>
        <Progress
          sx={{ marginTop: "4px" }}
          w="full"
          value={progressPublicSaleRatio || 1}
          size="sm"
          bgColor="rgba(147, 240, 245, 0.25)"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <div>{formatNumDynDecimal(publicSaleAmount?.purchased)}</div>
          <div>{formatNumDynDecimal(publicSaleAmount?.total)}</div>
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px", marginBottom: "8px" }}>
        <Text sx={headerSX}>{`Public amount (max: ${formatNumDynDecimal(
          maxAmount
        )})`}</Text>
        <IWInput
          isDisabled={!allowBuy || !(+maxAmount > 0)}
          onChange={({ target }) => {
            setAmount(target.value);
            const a0price = multiplePrice(target.value, tokenPrice);
            if (a0price < MINIMUM_LAUNCHPAD_PURCHASE) {
              setAmountError(true);
              // setAzeroBuyAmount(0.1);
            } else {
              setAmountError(false);
              setAzeroBuyAmount(roundUp(a0price, 6));
            }
            setIsBuyWithA0(false);
          }}
          type="number"
          value={amount}
          placeholder="0"
          inputRightElementIcon={launchpadData?.projectInfo?.token?.symbol}
        />
      </Box>
      <IWInput
        isDisabled={!allowBuy || !(+maxAmount > 0)}
        onChange={({ target }) => {
          setAzeroBuyAmount(target.value);
          if (+target.value < MINIMUM_LAUNCHPAD_PURCHASE) {
            setAmountError(true);
          } else setAmountError(false);
          setAmount(roundDown(+target.value / +tokenPrice, 12));
          setIsBuyWithA0(true);
        }}
        type="number"
        value={azeroBuyAmount}
        // label={`Amount (max)`}
        placeholder="0"
        inputRightElementIcon={<AzeroLogo />}
      />
      {amountError && (
        <Box
          sx={{
            fontSize: "14px",
            alignItems: "center",
            fontSize: 14,
            color: "red",
          }}
        >
          <Text>Minimum purchase amount is</Text>{" "}
          <Box sx={{ display: "flex" }}>
            <Text>{roundUp(0.1 / tokenPrice)}=0.1</Text>
            <div style={{ marginLeft: "4px" }}>
              <AzeroLogo />
            </div>
          </Box>
        </Box>
      )}
      <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
        Token price: {formatDecimalNumberToString(tokenPrice)}
        <div style={{ marginLeft: "4px" }}>
          <AzeroLogo />
        </div>
      </div>
      <Box sx={{ display: "flex" }}>
        <Button
          isLoading={publicBuyMutation.isLoading}
          isDisabled={isBuyDisabled}
          sx={{ flex: 1, height: "40px", marginTop: "8px" }}
          onClick={() => publicBuyMutation.mutate()}
          spinner={<BeatLoader size={8} color="white" />}
        >
          Public Purchase
        </Button>
        {/* <Button
          sx={{
            flex: 1,
            marginLeft: "8px",
            height: "40px",
            marginTop: "8px",
          }}
          onClick={() => {}}
        >
          Whitelist Buy
        </Button> */}
      </Box>
    </>
  );
};

const SaleCard = ({ launchpadData }) => {
  const saleTime = useMemo(
    () =>
      launchpadData?.phaseList.map((e, index) => ({
        ...e,
        id: index,
        startTime: new Date(parseInt(e?.startTime?.replace(/,/g, ""))),
        endTime: new Date(parseInt(e?.endTime?.replace(/,/g, ""))),
      })),
    [launchpadData]
  );
  return (
    <Box
      sx={{
        border: "2.8px solid #93F0F5",
        borderRadius: "8px",
        paddingTop: "16px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "16px",
      }}
    >
      {saleTime?.length > 0 && (
        <IWCountDown launchpadData={launchpadData} saleTime={saleTime} />
      )}
    </Box>
  );
};

export default SaleCard;
