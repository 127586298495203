import {
  Flex,
  Link,
  Text,
  Show,
  Menu,
  MenuButton,
  MenuList,
  Heading,
  MenuItem,
} from "@chakra-ui/react";
import IWCard from "components/card/Card";

import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import routes from "routes.js";
import WalletButton from "components/wallet/WalletButton";
import { appChain } from "constants";
import ChainButton from "components/wallet/ChainButton";
import { toast } from "react-hot-toast";

export default function NavbarLinks(props) {
  const { secondary } = props;
  const [currentAnchor, setCurrentAnchor] = useState("");

  useEffect(() => {
    const href = window.location.href;
    const index = href.indexOf("#");
    const length = href.length;

    const shortenUrl = href.slice(index, length);

    shortenUrl === "#/"
      ? setCurrentAnchor("")
      : setCurrentAnchor(shortenUrl.replace("/", ""));
  }, []);

  const groupButtonProps = { setCurrentAnchor, currentAnchor };

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      justifyContent="end"
      bg="transparent"
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
    >
      <SidebarResponsive routes={routes} />

      <Show above="md">
        {/* <Flex
          _hover={{ textDecoration: "none", bg: "bg.1" }}
          p="6px 10px"
          bg={"transparent"}
          borderRadius="5px"
          minW={{ base: "0px", lg: "72px" }}
          justify={{ base: "normal", lg: "center" }}
        >
          <Link
            color={"text.1"}
            fontWeight="600"
            bg="transparent"
            textDecoration="none"
            _focus={{ borderWidth: "0px" }}
            _hover={{ textDecoration: "none", bg: "bg.1" }}
            onClick={() => window.open("https://app.common.fi/pools/5Dr3N2eP41e3BTMi6rxCJYeLGSS7Ggnayarx9FqCPZdmnnNj", "_blank")}
            display="flex"
            alignItems="center"
          >
            <Text bg="transparent" fontSize="md">
              Trade INW2
            </Text>
          </Link>
        </Flex> */}

        <Flex bg="transparent">
          <GroupMenu
            {...groupButtonProps}
            title="INW Token"
            path="/inw"
            data={inwTokenListData}
          />
          <GroupMenu
            {...groupButtonProps}
            title="Token"
            path="/token"
            data={tokenMenuListData}
          />{" "}
          {menuListData?.map(({ title, href }) => (
            <Flex
              _hover={{ textDecoration: "none", bg: "bg.1" }}
              p="6px 10px"
              bg={
                (!currentAnchor && href === "#hero") || currentAnchor === href
                  ? "bg.1"
                  : "transparent"
              }
              borderRadius="5px"
              key={title}
              // ml={{ base: "20px", md: "20px" }}
              minW={{ base: null, lg: "80px" }}
            >
              <Link
                to={href}
                as={RouterLink}
                color={"text.1"}
                fontWeight="600"
                bg="transparent"
                textDecoration="none"
                _focus={{ borderWidth: "0px" }}
                _hover={{ textDecoration: "none", bg: "bg.1" }}
                onClick={() => setCurrentAnchor(href)}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text bg="transparent" fontSize="md" textAlign="center">
                  {title}
                </Text>
              </Link>
            </Flex>
          ))}
          <GroupMenu
            {...groupButtonProps}
            title="Pools / Farms "
            path="/pools"
            data={poolsMenuListData}
          />
          {[
            {
              title: `Stake ${appChain?.unit} `,
              href: "/azero-staking",
            },
          ].map(({ title, href }) => (
            <Flex
              _hover={{ textDecoration: "none", bg: "bg.1" }}
              p="6px 10px"
              bg={
                (!currentAnchor && href === "#hero") || currentAnchor === href
                  ? "bg.1"
                  : "transparent"
              }
              borderRadius="5px"
              key={title}
              // ml={{ base: "20px", md: "20px" }}
              minW={{ base: null, lg: "80px" }}
            >
              <Link
                to={href}
                as={RouterLink}
                color={"text.1"}
                fontWeight="600"
                bg="transparent"
                textDecoration="none"
                _focus={{ borderWidth: "0px" }}
                _hover={{ textDecoration: "none", bg: "bg.1" }}
                onClick={() => setCurrentAnchor(href)}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text bg="transparent" fontSize="md" textAlign="center">
                  {title}
                </Text>
              </Link>
            </Flex>
          ))}
          {/* <Flex
            _hover={{ textDecoration: "none", bg: "bg.1" }}
            p="6px 10px"
            bg={"transparent"}
            borderRadius="5px"
            mr={{ base: "20px", md: "20px" }}
          >
            <Link
              color={"text.1"}
              fontWeight="600"
              bg="transparent"
              textDecoration="none"
              _focus={{ borderWidth: "0px" }}
              _hover={{ textDecoration: "none", bg: "bg.1" }}
              onClick={() => toast.success("Coming soon!")}
            >
              <Text bg="transparent" fontSize="md">
                Launchpad
              </Text>
            </Link>
          </Flex> */}
        </Flex>
      </Show>

      <Show above="md">
        <Flex>
          {/* <ChainButton /> */}
          <WalletButton />
        </Flex>
      </Show>
    </Flex>
  );
}

NavbarLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

export const menuListData = [
  {
    title: "Launchpad",
    href: "/launchpad",
  },
];

export const inwTokenListData = [
  {
    label: "Claim INW",
    href: "/acquire-inw",
  },
  {
    label: "Swap INW",
    href: "/inw-v2",
  },
  // {
  //   label: "Bridge to 5irechain",
  //   href: "/bridge",
  // },
];

export const tokenMenuListData = [
  {
    label: "PSP22 Tokens",
    href: "/tokens",
  },
  {
    label: "Create a PSP22 token",
    href: "/create/token",
  },
  {
    label: "Interact with a token",
    href: "/tokens/interaction",
  },
  {
    label: "Swap Token to V2",
    href: "/tokens/swap/5GYgJ1xBPtyUwbPVnDfbg9uRGWdGrcaM6y1TaftUMoxUHQh5",
  },
  {
    label: "Transaction history",
    href: "/tokens/transaction",
  },
  // {
  //   label: "Transactions on Common",
  //   href: "/tokens/swap/history",
  // },
];

export const poolsMenuListData = [
  {
    label: "Token Pools",
    href: "/pools",
  },
  { label: "NFT Pools", href: "/farms" },
  { label: "Token Farming", href: "/farming" },
];

export const GroupMenu = ({
  title,
  path,
  data,
  onClose,
  setCurrentAnchor,
  currentAnchor,
}) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        p="0px"
        _hover={{ bg: "bg.1" }}
        bg={currentAnchor === path ? "bg.1" : "transparent"}
        borderRadius="5px"
      >
        <Flex
          w="full"
          p="6px 10px"
          borderRadius="5px"
          display="flex"
          justify={{ base: "normal", lg: "center" }}
          minW={{ base: "72px" }}
        >
          <Link color={"text.1"} fontWeight="600" textDecoration="none">
            <Text fontSize="md">{title}</Text>
          </Link>
        </Flex>
      </MenuButton>

      <MenuList
        p="0px"
        m="0px"
        border="none"
        borderRadius="10px"
        boxShadow="0px 10px 21px rgba(0, 0, 0, 0.08)"
      >
        <Flex flexDirection="column" p="20px">
          {data
            ?.map((e) => ({
              ...e,
              onClick: () => {
                if (onClose) onClose();
                setCurrentAnchor(path);
              },
            }))
            .map((item, idx) => (
              <IWCard
                key={idx}
                mb="0px"
                px="-24px"
                alignItems={{ base: "center" }}
                cursor="pointer"
                variant="menuBlank"
                minW={{ base: "full", lg: "180px" }}
              >
                <Link
                  _hover={{ textDecoration: "none" }}
                  to={item?.href}
                  as={item?.href && RouterLink}
                  color={"text.1"}
                  fontWeight="600"
                  bg="transparent"
                  textDecoration="none"
                  _disabled={true}
                  onClick={item?.onClick}
                >
                  <MenuItem
                    _active={{ bg: "transparent" }}
                    _focus={{ bg: "transparent" }}
                  >
                    <Flex
                      w="full"
                      justify={{ base: "start" }}
                      alignItems={{ base: "center" }}
                    >
                      <Heading as="h5" size="h5" ml="10px">
                        {item.label}
                      </Heading>
                    </Flex>
                  </MenuItem>
                </Link>
              </IWCard>
            ))}
        </Flex>
      </MenuList>
    </Menu>
  );
};
