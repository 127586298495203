export const radioStyles = {
  components: {
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            color: "brand.500", // Customize the checked color here
          },
        },
      },
    },
  },
};
