import { ChakraProvider } from "@chakra-ui/react";
import { ApiPromise, WsProvider } from "@polkadot/api";
import "assets/css/App.css";
import { toastMessages } from "constants";
import DefaultLayout from "layouts/default";
import "rc-steps/assets/index.css";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import ReactDOM from "react-dom";
import { Toaster, toast } from "react-hot-toast";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import theme from "theme/theme";
import { formatChainStringToNumber } from "utils";
import store from "./redux/store";

import jsonrpc from "@polkadot/types/interfaces/jsonrpc";
import { AppContextProvider, useAppContext } from "contexts/AppContext";
import MyBalancePage from "pages/account/my-balance";
import MyPoolsPage from "pages/account/my-pools";
import MyPoolDetailPage from "pages/account/my-pools/detail";
import AdminPage from "pages/admin";
import CreateNFTLPPage from "pages/create/nft-lp-pool";
import CreateStakePoolPage from "pages/create/stake-pool";
import CreateTokenPage from "pages/create/token";
import CreateTokenLPPage from "pages/create/token-lp-pool";
import FarmsPage from "pages/farms";
import FarmDetailPage from "pages/farms/detail";
import FaucetPage from "pages/faucet";
import Launchpad from "pages/launchpad";
import CreateLaunchpadPage from "pages/launchpad/create";
import PublicDetailLaunchpad from "pages/launchpad/detail";
import LPPoolsPage from "pages/lpPools";
import PoolsPage from "pages/pools";
import PoolDetailPage from "pages/pools/detail";
import TokensPage from "pages/tokens";
import TokensTransactionPage from "pages/tokens/transactions";
import { QueryClient, QueryClientProvider } from "react-query";
import { fetchAllTokensList } from "redux/slices/allPoolsSlice";
import { fetchTotalValueLocked } from "redux/slices/statSlice";
import { fetchUserBalance } from "redux/slices/walletSlice";
import { delay } from "utils";
import { initialApi } from "utils/contracts";
import AzeroStaking from "pages/azero-staking";
import INWV2 from "pages/faucet/inwV2";
import BridgePage from "pages/account/bridge";
import TokensSwapHistoryPage from "pages/tokens/swap/history";
import TokensSwapPage from "pages/tokens/swap";
import LoginPage from "pages/login";
import PSP22Tokens from "pages/psp22Tokens";
import TokenDetailPage from "pages/psp22Tokens/detail";

const providerUrl = process.env.REACT_APP_PROVIDER_URL;
const queryClient = new QueryClient();

const loginPass = process.env.REACT_APP_LOGIN_PASSWORD;

const App = () => {
  const dispatch = useDispatch();

  const { currentAccount, allAccounts, currentExt } = useSelector(
    (s) => s.wallet
  );
  const { myStakingPoolsList, myNFTPoolsList, myTokenPoolsList } = useSelector(
    (s) => s.myPools
  );
  const {
    allTokensList,
    allStakingPoolsList,
    allNFTPoolsList,
    allTokenPoolsList,
  } = useSelector((s) => s.allPools);
  const { setCurrentApi } = useAppContext();
  const { launchpads } = useSelector((s) => s.launchpad);
  const [api, setApi] = useState(null);
  const uiColorMode = localStorage.getItem("chakra-ui-color-mode");

  if (!uiColorMode || uiColorMode === "dark") {
    localStorage.setItem("chakra-ui-color-mode", "light");
  }

  useEffect(() => {
    const setupProvider = async () => {
      toast(`Connecting to ${providerUrl}...`);
      const provider = new WsProvider(providerUrl);

      const wsApi = await ApiPromise.create({
        provider,
        rpc: jsonrpc,
        throwOnConnect: true,
        types: {
          PhaseInput: {
            name: "String",
            startTime: "u64",
            endTime: "u64",
            immediateReleaseRate: "u32",
            vestingDuration: "u64",
            vestingUnit: "u64",
            capAmount: "Balance",
            isPublic: "bool",
            publicAmount: "Balance",
            publicPrice: "Balance",
          },
        },
      });

      if (!wsApi) return;

      console.log(`Successfully connected to: ${providerUrl}`);
      toast.success(`Successfully connected !`);

      setApi(wsApi);

      initialApi(wsApi);

      setCurrentApi(wsApi);

      await wsApi.rpc.chain.subscribeNewHeads((lastHeader) => {
        // eslint-disable-next-line no-unused-vars
        const lastBlock = formatChainStringToNumber(
          JSON.stringify(lastHeader.number.toHuman())
        );

        // setLastChainBlock(lastBlock);
        // setLastBlockParent(lastHeader.parentHash.toRawType);
      });
    };

    setupProvider().catch((error) => {
      toast.error(toastMessages.ERR_API_CONN);
      console.error("@_@ setupProvider error", error);
    });
  }, [dispatch]);

  // useEffect(() => {
  //   if (currentExt?.length > 0 && currentAccount) {
  //     if (!currentExt?.find((e) => e?.name == currentAccount?.meta?.source)) {
  //       dispatch(disconnectCurrentAccount());
  //       dispatch(logOutMyPools());
  //       localStorage.removeItem("localCurrentAccount");
  //     }
  //   }
  // }, [currentExt, currentAccount]);

  useEffect(() => {
    if (api) {
      delay(100);

      if (!allTokensList) {
        dispatch(fetchAllTokensList({}));
      }

      dispatch(fetchTotalValueLocked());

      if (!currentAccount?.address) return;

      if (!currentAccount?.balance) {
        dispatch(fetchUserBalance({ currentAccount, api }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentAccount?.address]);

  return (
    <HashRouter>
      <DefaultLayout>
        <Switch>
          <Redirect exact from="/" to={`/acquire-inw`} />
          <Route exact path={`/login`}>
            <LoginPage />
          </Route>
          <Route exact path={`/acquire-inw`}>
            <FaucetPage api={api} />
          </Route>
          <Route exact path={`/pools/:contractAddress`}>
            <PoolDetailPage api={api} />
          </Route>
          <Route exact path={`/pools`}>
            <PoolsPage api={api} />
          </Route>
          <Route
            exact
            path={`/farms/:contractAddress`}
            component={FarmDetailPage}
          />
          <Route exact path={`/farms`} component={FarmsPage} />
          <Route exact path={`/tokens/interaction`} component={TokensPage} />
          <Route
            exact
            path={`/tokens/transaction`}
            component={TokensTransactionPage}
          />
          {/* <Route
            exact
            path={`/tokens/swap/history`}
            component={TokensSwapHistoryPage}
          /> */}
          <Route
            exact
            path={`/tokens/swap/:tokenAddress`}
            component={TokensSwapPage}
          />
          <Route exact path={`/create/token`} component={CreateTokenPage} />
          <Route exact path={`/tokens`} component={PSP22Tokens} />
          <Route exact path={`/tokens/:tokenAddress`} component={TokenDetailPage} />
          <Route
            exact
            path={`/create/stake-pool`}
            component={CreateStakePoolPage}
          />
          <Route exact path={`/create/nft-lp`} component={CreateNFTLPPage} />
          <Route
            exact
            path={`/launchpad/create`}
            component={CreateLaunchpadPage}
          />
          <Route exact path={`/launchpad`} component={Launchpad} />
          <Route
            exact
            path={`/launchpad/:launchpadContract`}
            component={PublicDetailLaunchpad}
          />
          <Route exact path={`/create/farming`} component={CreateTokenLPPage} />
          <Route exact path={`/farming`} component={LPPoolsPage} />
          <Route
            exact
            path={`/farming/:contractAddress`}
            component={FarmDetailPage}
          />
          <Route exact path={`/account`} component={MyBalancePage} />
          <Route exact path={`/account/my-balance`} component={MyBalancePage} />
          <Route exact path={`/my-pools`} component={MyPoolsPage} />
          <Route
            exact
            path={`/my-pool/:contractAddress`}
            component={MyPoolDetailPage}
          />
          <Route
            exact
            path={`/my-farm/:contractAddress`}
            component={MyPoolDetailPage}
          />
          <Route
            exact
            path={`/my-farming/:contractAddress`}
            component={MyPoolDetailPage}
          />
          <Route exact path={`/admin`} component={AdminPage} />
          <Route exact path={`/azero-staking`} component={AzeroStaking} />
          <Route exact path={`/inw-v2`} component={INWV2} />
          {/* <Route exact path={`/bridge`} component={BridgePage} /> */}
          <Route>
            <FaucetPage api={api} />
          </Route>
        </Switch>
      </DefaultLayout>
    </HashRouter>
  );
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <AppContextProvider>
            <Toaster
              position="bottom-right"
              reverseOrder={true}
              toastOptions={{
                style: {
                  padding: "8px",
                  fontSize: "16px",
                  color: "#57527E",
                  borderRadius: "5px",
                  background: "#E8FDFF",
                },
              }}
            />
            <App />
          </AppContextProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
